import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import backgroundImage from "../../../assets/background.jpeg";
import HeroCard from "../HeroCard/HeroCard";
import NewsCard from "../NewsCard/NewsCard";
import newspaper from "../../../assets/newpaper.png";
import home from "../../../assets/home.png";
import palmyra from "../../../assets/palmyra2.png";
import SectionTitle from "../SectionTItle/SectionTitle";
import axios from "axios";

function Politics() {
  const [newslist, setNewsList] = useState([]);

  useEffect(() => {
    const getPolitics = () => {
      axios
        .get(
          `https://www.tanaghomtech.com/magazine/public/api/article?include=category,writer,country&filter[category_id]=1`
        )
        .then((response) => {
          setNewsList(response.data.data);
          console.log(response.data.data);

        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );

        });
    };

    getPolitics();
  }, []);
  return (
    <>
      <SectionTitle sectionName="سياسة" />
      <Box
        id="policy"
        sx={{
          flexGrow: 1,
          marginTop: "1rem",
          height: { sx: "auto", md: "100vh" },
        }}
      >
        <Grid container spacing={1}>
          {/* First card on the left side */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <HeroCard
                title={newslist[0]?.title}
                writer={newslist[0]?.writer.writerName}
                category="سياسة"
                date={new Date(newslist[0]?.created_at).toLocaleTimeString("default", {
                  year: "numeric", month: "numeric", day: "numeric", time: "numeric"
                })}
                backgroundImage={"https://www.tanaghomtech.com/magazine/storage/app/public/" + newslist[0]?.imageLink}
                size="70vh"
                paragraph={newslist[0]?.brief}
                categoryColor="#FF5733"
                link={"/article/" + newslist[0]?.id}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
              title={newslist[1]?.title}
              date={new Date(newslist[1]?.created_at).toLocaleTimeString("default", {
                year: "numeric", month: "numeric", day: "numeric", time: "numeric"
              })}
              writer={newslist[1]?.writer.writerName}
              imageUrl={"https://www.tanaghomtech.com/magazine/storage/app/public/" + newslist[1]?.imageLink}
              link={"/article/" + newslist[1]?.id}
            />{" "}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
             title={newslist[2]?.title}
             date={new Date(newslist[2]?.created_at).toLocaleTimeString("default", {
               year: "numeric", month: "numeric", day: "numeric", time: "numeric"
             })}
             writer={newslist[2]?.writer.writerName}
             imageUrl={"https://www.tanaghomtech.com/magazine/storage/app/public/" + newslist[2]?.imageLink}
             link={"/article/" + newslist[2]?.id}
            />{" "}
          </Grid>

          {/* Bottom card under the top two, spanning their combined width */}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <NewsCard
             title={newslist[3]?.title}
             date={new Date(newslist[3]?.created_at).toLocaleTimeString("default", {
               year: "numeric", month: "numeric", day: "numeric", time: "numeric"
             })}
             writer={newslist[3]?.writer.writerName}
             imageUrl={"https://www.tanaghomtech.com/magazine/storage/app/public/" + newslist[3]?.imageLink}
             link={"/article/" + newslist[3]?.id}
            />{" "}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Politics;
