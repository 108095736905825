import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import img1 from "../../../assets/flag.png";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "white",
  borderRadius: "0",
}));

export default function QuiltedImageList() {
  return (
    <Grid
      sx={{
        direction: "rtl",
        borderTop: "1px solid black",
        paddingTop: "50px",
        marginTop:'50px'
      }}
      container
      spacing={2}
    >
      <Grid xs={12} md={4}>
        <Stack spacing={2}>
          <Item
            sx={{ position: "relative", boxShadow: "none", textAlign: "right" }}
          >
            <img style={{ width: "100px" }} src={img1} alt="" />{" "}
            <div
              style={{
                display: "inline",
                position: "absolute",
                top: "25%",
                fontSize: "30px",
                color: "black",
                fontWeight: "800",
              }}
            >
              تجمع مستقلي هيئة التنسيق الوطنية
            </div>{" "}
          </Item>

          <Item
            sx={{
              position: "relative",
              textAlign: "center",
              alignContent: "space-between",
              boxShadow: "none",
            }}
          >
            <IconButton
              sx={{
                padding: "10px",
                borderRadius: "50%",
                backgroundColor: "red",
                color: "white",
                margin: "0 10px",
                ":hover": {
                  bgcolor: "red", // theme.palette.primary.main
                  color: "white",
                },
              }}
              color="primary"
              aria-label="add to shopping cart"
            >
              <YouTubeIcon />
            </IconButton>

            <IconButton
              sx={{
                padding: "10px",
                borderRadius: "50%",
                backgroundColor: "green",
                color: "white",
                margin: "0 10px",
                ":hover": {
                  bgcolor: "green", // theme.palette.primary.main
                  color: "white",
                },
              }}
            >
              {" "}
              <InstagramIcon />
            </IconButton>
            <IconButton
              sx={{
                padding: "10px",
                borderRadius: "50%",
                backgroundColor: "black",
                color: "white",
                margin: "0 10px",
                ":hover": {
                  bgcolor: "black", // theme.palette.primary.main
                  color: "white",
                },
              }}
            >
              <FacebookIcon />
            </IconButton>
          </Item>
        </Stack>
      </Grid>
      <Grid xs={12} md={4}>
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid xs={12}>
              <Item
                sx={{
                  color: "red",
                  textAlign: "right",
                  boxShadow: "none",
                  fontSize: "24px",
                  fontWeight: "800",
                }}
              >
                فئة
              </Item>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                <Item sx={{ backgroundColor: "red" }}>سياسة</Item>{" "}
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                {" "}
                <Item sx={{ backgroundColor: "red" }}>مقابلات</Item>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                {" "}
                <Item sx={{ backgroundColor: "red" }}>اتجاهات نظرية</Item>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                {" "}
                <Item sx={{ backgroundColor: "green" }}>حقوق وحريات</Item>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                {" "}
                <Item sx={{ backgroundColor: "green" }}>تحليل</Item>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                {" "}
                <Item sx={{ backgroundColor: "green" }}>رأي</Item>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                {" "}
                <Item sx={{ backgroundColor: "black" }}>اقتصاد</Item>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                {" "}
                <Item sx={{ backgroundColor: "black" }}>ملتيميديا</Item>
              </Link>
            </Grid>
            <Grid xs={4}>
              <Link href="#" underline="none">
                {" "}
                <Item sx={{ backgroundColor: "black" }}>بيانات</Item>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid xs={12} md={4}>
        <Stack spacing={2}>
          <Item
            sx={{
              color: "red",
              textAlign: "right",
              boxShadow: "none",
              fontSize: "24px",
              fontWeight: "800",
            }}
          >
            تواصل معنا
          </Item>
          <Item
            sx={{
              color: "black",
              textAlign: "right",
              boxShadow: "none",
              fontSize: "20px",
            }}
          >
            ليتم إطلاعكم على بأحدث الأخبار والعروض والإعلانات الخاصة{" "}
          </Item>
          <Item
            sx={{
              color: "black",
              textAlign: "right",
              boxShadow: "none",
              direction: "ltr",
            }}
          >
            <TextField
              id="outlined-textarea"
              label="بريدك الإلكتروني"
              placeholder="بريدك الإلكتروني"
              sx={{ width: "80%" }}
            />
          </Item>

          <Item
            sx={{
              color: "black",
              textAlign: "right",
              boxShadow: "none",
              direction: "ltr",
            }}
          >
            <Button
              sx={{
                backgroundColor: "red",
                ":hover": {
                  bgcolor: "black", // theme.palette.primary.main
                  color: "white",
                },
              }}
              variant="contained"
              endIcon={<SendIcon sx={{ transform: "rotate(180deg)" }} />}
            >
              إرسال
            </Button>
          </Item>
        </Stack>
      </Grid>
      <Grid xs={12} md={12}>
        <Item
          sx={{
            color: "black",
            boxShadow: "none",
            fontSize: "16px",
            fontWeight: "800",
          }}
        >
          جميع الحقوق محفوظة 2024 <CopyrightIcon />
        </Item>
      </Grid>
    </Grid>
  );
}
